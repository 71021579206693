import React from 'react';
import { Link } from 'react-router-dom';
import loadingBg from "./assets/gamebg.png"

const NotFound = () => {
    return (
        <div className='w-full h-screen flex justify-center flex-col items-center bg-primary-green text-gray-200'
        style={{
            backgroundImage: `url(${loadingBg})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            }}
        >
            <h1 className="big-text font-bold text-white">404</h1>
            <p className="text-4xl text-white font-sofia-pro">Page not found</p>
            <Link
                to="/"
                className="px-4 py-2 mt-8 text-white  border-2 border-gray-800 bg-red-500 rounded hover:bg-gray-700"
            >
                Back to Home
            </Link>
        </div>
    );
};

export default NotFound;
