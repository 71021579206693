import React, { Suspense, lazy } from 'react'
import Home from './pages/home/Home'
import Pepsi from './pages/pepsi/Pepsi'
import { Routes, Route, useLocation } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import NotFound from './NotFound'
import ScrollToTopButton from './components/ScrollToTop'
import Loading from './components/Loading'
const App = () => {
  const location = useLocation()

  const LazyAboutUs = lazy(() => import('./pages/about-us/AboutUs'))
  const LazyContactUs = lazy(() => import('./pages/contact-us/ContactUs'))
  const LazyProducts = lazy(() => import('./pages/products/Products'))
  const LazyMedia = lazy(() => import('./pages/media/Media'))
  const LazyGame = lazy(() => import('./pages/game/Game'))
  const LazyPlayGame = lazy(() => import('./pages/playgame/PlayGame'))
  const LazyCareer = lazy(() => import('./pages/career/Career'))
  const LazyPrivacy = lazy(() => import('./pages/privacy-policy/Privacy'))



  return (
    <TransitionGroup>
      <CSSTransition key={location.key} classNames="fade" timeout={300}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/about-us"
            element={
              <Suspense fallback={<Loading />}>
                <LazyAboutUs />
              </Suspense>
            }
          />
          <Route
            path="/contact-us"
            element={
              <Suspense fallback={<Loading />}>
                <LazyContactUs />
              </Suspense>
            }
          />
          <Route
            path="/products"
            element={
              <Suspense fallback={<Loading />}>
                <LazyProducts />
              </Suspense>
            }
          />
          <Route
            path="/media"
            element={
              <Suspense fallback={<Loading />}>
                <LazyMedia />
              </Suspense>
            }
          />
          <Route path="/game" element={<Suspense fallback={<Loading />}><LazyGame /></Suspense>} />
          <Route path="/game/playgame" element={<Suspense fallback={<Loading />}><LazyPlayGame /></Suspense>} />
          <Route
            path="/career"
            element={
              <Suspense fallback={<Loading />}>
                <LazyCareer />
              </Suspense>
            }
          />
          <Route
            path="/privacy-policy"
            element={
              <Suspense fallback={<Loading />}>
                <LazyPrivacy />
              </Suspense>
            }
          />
          <Route path="/pepsi" element={<Pepsi />} />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </CSSTransition>
      <ScrollToTopButton />
    </TransitionGroup>
  );
}

export default App