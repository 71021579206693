import React from "react";
import logo from "../assets/sbclogo.png";

import { Link } from "react-router-dom";
const Win = ({currentTime}) => {

  const handleShare = async () => {
    try {
        if (navigator.share) {

          await navigator.share({
            title: "SevenUp",
            text: "I just played the SevenUp game and it was fun!",
            url: window.location.href,
          });
        } else {
          console.log("Web share not supported");
        }
    } catch (error) {
        console.log(error);
    }
  }

  // handle restart game
  const handleRestart = () => {
    window.location.reload();
  };

  return (
    <div
      className="w-full h-screen flex flex-col items-center "
      style={{
        backgroundImage: "url('/gamebg.png')",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        backgroundBlendMode: "overlay",
      }}
    >
      <div
        className=" absolute top-0 left-0 w-full h-full flex flex-col items-center justify-center z-[-1]"
        style={{
          backgroundImage: "url('/gamebg.png')",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
        }}
      ></div>

      <div
        className="absolute top-0 left-0 w-full h-full flex flex-col items-center justify-center z-[-1]  md:bg-cover bg-contain bg-center"
        style={{
          backgroundImage: "url('/confetti.png')",
          // backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundBlendMode: "overlay",
        }}
      ></div>

      <div className="flex gap-4 items-center justify-between w-full mt-4 px-4 z-10 ">
        <Link to="/">
        <img src={logo} alt="logo" className="w-20 " />
        </Link>
        <div className=""></div>
        <div className=""></div>
      </div>
      <div className="mt-20 md:mt-0">
        <div className="flex flex-col justify-center items-center">
            <p className="text-3xl mt-1 font-sofia-pro uppercase text-primary-orange"> Congratulations </p>
            <h1 className=" big-text text-secondary-green font-bebas-kai uppercase"> Sabi Pesin </h1>
            <p className="text-3xl mt-1 font-sofia-pro uppercase text-primary-orange"> Well Done! </p>
        </div>
        <div className="flex flex-col justify-center items-center mt-8 md:mt-0">
            <p className="time-txt mt-1 font-sofia-pro uppercase text-primary-orange "> {currentTime} </p>
            <div className="flex  md:w-1/2 justify-between gap-4 md:gap-0 md:mt-0 mt-4 font-sofia-pro text-lg text-primary-orange">
                <span className="">Min</span>
                <span className="">Sec</span>
            </div>
        </div>
        {/* a share button */}
        <div className="flex justify-center items-center mt-2">

        <div className="flex justify-center items-center mt-2"
          onClick={handleShare}
        >
          <button className="px-12 py-2 text-primary-orange font-sofia-pro text-2xl uppercase mt-8 border-2 border-primary-orange hover:text-white hover:border-none hover:bg-primary-green transition-all duration-500">
            Share
          </button>
        </div>
        {/* a restart button */}
        <div className="flex justify-center items-center mt-2"
          onClick={handleRestart}
        >
          <button className="px-12 py-2 text-primary-orange font-sofia-pro text-2xl uppercase mt-8 border-2 border-primary-orange hover:text-white hover:border-none hover:bg-primary-green transition-all duration-500">
            Play Again
          </button>
        </div>
        </div>
      </div>
    </div>
  );
};

export default Win;
