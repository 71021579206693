import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const FadeIn = ({ children, classname, duration = 0.8, ease = "easeInOut" }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={{
        visible: {
          opacity: 1,
          y: 0,
          scale: 1,
          transition: { duration, ease },
        },
        hidden: { opacity: 0, y: 50, scale: 0.9, transition: { duration: 0.2 } },
      }}
      className={classname}
    >
      {children}
    </motion.div>
  );
};

export default FadeIn;
