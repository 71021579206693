import React, {  lazy, Suspense } from 'react'
import {  Info, Info2, Footer } from '../../components'
import Loading from '../../components/Loading'
import FadeIn from '../../components/FadeIn'
const Home = () => {
  const Hero = lazy(() => import('../../components/Hero'))
  const GameSection = lazy(() => import('../../components/GameSection'))
  const OurPeople = lazy(() => import('../../components/OurPeople'))


  return (
     <Suspense fallback={<Loading />}>
   
        <Hero />
        <GameSection />
        <OurPeople />
        <Info />
        <Info2 />
        <Footer />
    
    </Suspense>
  )
}

export default Home