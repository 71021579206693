import React, { useState, useEffect } from "react";
import { MdOutlineDoubleArrow } from "react-icons/md";
const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Show button when page is scrolled down
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Scroll to top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  return (
    isVisible && (
      <button
        onClick={scrollToTop}
        className="fixed bottom-10 right-10  bg-primary-green  text-white font-bebas-kai text-2xl p-2 rounded-full"
      >
       <MdOutlineDoubleArrow  className="transform -rotate-90
       " />
      </button>
    )
  );
};


export default ScrollToTopButton;