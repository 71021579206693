
import React from "react";
import { HiLink } from "react-icons/hi";
import { BiLogoFacebook } from "react-icons/bi";
import { BsInstagram, BsLinkedin } from "react-icons/bs";
import { Link } from "react-router-dom";
import footerbg from "../assets/footerbg.png";
import mapImage from "../assets/mapImage.png";
const Footer = () => {
  const year = new Date().getFullYear();

  // get current url to enable user share the page
  const currentUrl = "https://www.sevenup.org";

  return (
    <div className="w-full lg:h-[75vh] text-white font-sofia-pro flex flex-col">
      <div className="flex flex-col lg:flex-row h-full">
        <div
          className="flex-auto bg-primary-green flex flex-col md:justify-center items-start px-5 py-5 md:px-12 gap-4"
          style={{
            backgroundImage:`url(${footerbg})`,
            backgroundSize: "100% 100%",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            // backgroundBlendMode: "screen",
          }}
        >
          <h1 className="text-6xl text-white  font-bebas-kai tracking-tight">
            Head Office
          </h1>
          <p className="text-lg md:text-2xl font-semibold text-white md:mt-4 font-sofia-pro">
            <a href="https://www.sevenup.org" className="text-white ">
              SevenUp Bottling Company Ltd.
            </a>{" "}
            <br />
            <a
              href="https://goo.gl/maps/7J6YjZ6QJj1zv5yJ9"
              className="text-white "
            >
              247 Moshood Abiola Way,
            </a>{" "}
            <br />{" "}
            <a
              href="https://goo.gl/maps/7J6YjZ6QJj1zv5yJ9"
              className="text-white "
            >
              Ijora, Lagos, Nigeria.
            </a>
          </p>
          <div className="flex flex-col font-sofia-pro">
            <a
              href="mailto:info@sevenup.org"
              className="text-lg md:text-2xl font-semibold text-white mt-4 hover:underline"
            >
              {" "}
              e:info@sevenup.org{" "}
            </a>
            <a
              href="tel:+2348056900900"
              className="text-lg md:text-[1.5rem] font-semibold text-white hover:underline"
            >
              t: 0805 6900 900 / 0806 6900 900
            </a>
          </div>
        </div>
        <div
          className="lg:w-[65%] flex-auto bg-[#FB562A] flex flex-col justify-center items-start px-6 pt-8 gap-4"
          style={{
            backgroundImage: `url(${footerbg})`,
            backgroundSize: "100% 100%",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            backgroundBlendMode: "screen",
          }}
        >
          <div className="flex flex-col md:flex-row w-full gap-6 justify-center items-start">
            <div className="flex flex-col gap-4">
              <h1 className="text-6xl  text-white  font-bebas-kai tracking-tight">
                Regional Offices
              </h1>
              <p className="text-lg md:text-2xl font-semibold text-white mt-1 font-sofia-pro uppercase">
                {" "}
                NORTH-WEST ZONE{" "}
              </p>
              <p className="text-lg md:text-2xl font-semibold text-white mt-1 font-sofia-pro uppercase">
                {" "}
                NORTH-CENTRAL ZONE{" "}
              </p>
              <p className="text-lg md:text-2xl font-semibold text-white mt-1 font-sofia-pro uppercase">
                {" "}
                SOUTH-WEST ZONE{" "}
              </p>
              <p className="text-lg md:text-2xl font-semibold text-white mt-1 font-sofia-pro uppercase">
                {" "}
                SOUTH-EAST ZONE{" "}
              </p>
            </div>
            <div className=" mt-4 w-full md:w-3/5 h-auto md:h-[300px] object-cover  inline-block overflow-hidden ">
              {/* <iframe
                src="https://www.google.com/maps/d/u/0/embed?mid=1bqfhxLrtW291UIG_4SmdIrNCn8v7Jfc&ehbc=2E312F&noprof=1"
                width="400"
                height="500"
                className="w-full object-cover"
                title="Google Maps"
                style={{
                  position: "relative",
                  top: "-50px",
                }}
              ></iframe> */}
              <img src={mapImage} alt="map" className="w-full object-cover" />
            </div>
          </div>
          {/* <div className="mt-8 mb-8">
            <h2 className="uppercase text-2xl mt-4 ">Customer service Lines</h2>
            <div className="flex flex-wrap gap-4 mt-4 text-xl">
              <div className="pr-2 md:pr-8 border-r-2 border-white">
                <a href="tel:+2348056900900" className="text-white">
                  {" "}
                  080 5690 0900
                </a>
              </div>
              <div className="px-2 md:px-8 border-r-2 border-white">
                <a href="tel:+2348066900900" className="text-white">
                  {" "}
                  080 6690 0900
                </a>
              </div>
              <div className="px-2 md:px-8 border-r-2 border-white">
                <a href="tel:+2348076900900" className="text-white">
                  {" "}
                  080 7690 0900
                </a>
              </div>
              <div className="px-2 md:px-8 ">
                <a href="tel:+2348086900900" className="text-white">
                  {" "}
                  080 8690 0900
                </a>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <div className="bg-black w-full lg:h-[80px] flex flex-col gap-6 lg:flex-row justify-between items-center px-2 md:px-8 py-4">
        <div className="flex gap-2 items-center">
          <h4 className="text-xl ml-4">Share</h4>
          <div className="rounded-full bg-transparent ml-4 border-2 border-white p-2">
            <a href={`https://wa.me/?text=${currentUrl}`} 
            rel="noreferrer"
            target="_blank">
              <HiLink className="text-white text-xl " />
            </a>
          </div>
          <div className="rounded-full bg-transparent ml-4 border-2 border-white p-2">
            <a href="https://www.facebook.com/SBCNigeria"
            rel="noreferrer"
             target="_blank">
              <BiLogoFacebook className="text-white text-xl " />
            </a>
          </div>
          <div className="rounded-full bg-transparent ml-4 border-2 border-white p-2">
            <a href="https://www.instagram.com/sbc_nigeria/" 
            rel="noreferrer"
            target="_blank">
              <BsInstagram className="text-white text-xl " />
            </a>
          </div>
          <div className="rounded-full bg-transparent ml-4 border-2 border-white p-2">
            <a
              href="https://www.linkedin.com/company/589463/admin/feed/posts/"
              target="_blank"
              rel="noreferrer"
            >
              <BsLinkedin className="text-white text-xl " />
            </a>
          </div>
        </div>
        <div className="">
          <h4 className="text-sm md:text-xl mt-4 md:mt-0 ">
            © {year} Seven-Up Bottling Company All Rights Reserved.
          </h4>
        </div>
        <Link to="/privacy-policy">
        <div className="">
          <h4 className="text-sm md:text-xl mt-4 md:mt-0">Privacy Policy</h4>
        </div>
        </Link>
      </div>
    </div>
  );
};

export default Footer;
