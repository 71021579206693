import React from "react";
import FadeIn from "./FadeIn";
import info2 from "../assets/info2.png";
import { Link } from "react-router-dom";
const Info2 = () => {
  return (
    <FadeIn>
    <div className="w-full lg:h-screen bg-white mt-0 ">
      <div className="flex flex-col  lg:flex-row">
        <div className="flex-1">
          <img src={info2} alt="pepsi" className="w-full md:h-screen" />
        </div>
        <div className="flex-1 bg-white flex flex-col justify-between p-2 md:p-8 px-4 md:px-16 mb-8 md:mb-0 ">
          <h1 className="uppercase text-primary-green font-bebas-kai text-6xl md:text-8xl tracking-tighter ">
            Refreshment <br /> and fun
          </h1>
          <div className="flex flex-col gap-4 font-semibold mt-4 md:text-[17px]">

          <p>
            SBC has a wide offering of sugar-sweetened and sugar-free
            non-alcoholic beverages. We can also boast of having Nigerian
            favourites like Pepsi, Seven-Up, Mirinda, Mountain Dew, and Teem in
            several variants and line extensions in our portfolio.
          </p>
          <p>
            Our history also contains iconic marketing campaigns such as ‘Fido
            Dido,’ ‘The Three Orange Men,’ and ‘The Pepsi Long Throat’ campaign.
          </p>
          <p>
            We are fueled by the need to ‘refresh and inspire a youthful
            lifestyle’ and our mission is guided by principles like hard work,
            determination, integrity, honesty, sustainability, fun, and
            refreshment. That’s why our brands champion and complement the music
            scene, sports, and the all-around ‘good life.’
          </p>
          <p>
            SBC products are also affordable and accessible to all economic
            classes with a premium offering.
          </p>
          </div>
          <Link href="/about-us">
          <div className="">
          <button className=" text-blue-500 px-10 py-4 font-sofia-pro text-2xl uppercase mt-10 border-2 border-blue-500 btn2 hover:text-white">
            learn more
          </button>
        </div>
        </Link>
        </div>
      </div>
    </div>
    </FadeIn>
  );
};

export default Info2;
