import React from 'react';
import ReactLoading from 'react-loading';
import loadingBg from "../assets/gamebg.png"

const Loading = () => {
    return (
        <div className='w-full h-screen flex justify-center items-center bg-primary-green'
        style={{
            backgroundImage: `url(${loadingBg})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            }}
        >
            
            <ReactLoading
                type={'bars'}
                color={'white'}
                height={'15%'}
                width={'15%'}
                className='mx-auto my-auto'
            />
          
        </div>
    );
};

export default Loading;
