import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'

if(typeof window !== 'undefined'){
  posthog.init('phc_kOL80BN8Rfkhpl2DSV3fVtXiKhJRHYRPf96fGMUPi5z', {api_host: 'https://app.posthog.com'})
}

export function PHProvider({ children }) {
    return <PostHogProvider client={posthog}>{children}</PostHogProvider>
  }

// phc_kOL80BN8Rfkhpl2DSV3fVtXiKhJRHYRPf96fGMUPi5z