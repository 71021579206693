
import { Link } from "react-router-dom";
import React, { useState, } from "react";
import gameImage from "../assets/game-section.png"
import whiteArrow from "../assets/white-right.png"
import greenStripe from "../assets/green-stripe.png"
import FadeIn from "./FadeIn";
const GameSection = () => {
  const [isHover, setIsHover] = useState(false);

  
  return (
    <FadeIn>
    <div
      className="w-full h-full md:min-h-screen flex flex-col md:gap-4 md:flex-row lg:flex-row relative md:overflow-clip object-fill bg-cover"
      style={{
        backgroundImage: `url(${gameImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        media: "(min-width: 768px)",
      }}
    >
      <div className="flex-1 flex flex-col  md:justify-center px-2 md:p-8 lg:px-20 lg:py-20 text-white bg-green-800 bg-blend-multiply bg-opacity-70 md:bg-transparent lg:bg-transparent  ">
        <div className="flex flex-col md:gap-4 justify-center px-4 py-2 md:px-0 md:py-0">
          <h1 className="uppercase font-bebas-kai text-[3.8rem] md:text-7xl leading-[60px]">how we started</h1>
          <p className="mt-2 text-base w-full md:w-5/6 font-sofia-pro">
            Just like Nigeria, the entity SevenUp Bottling Company was conceived
            in 1959 by a great visionary named Mohammed El-Khalil and began
            operations as a soft drink bottling company. <br /> <br /> As a
            company, we have since achieved great success in the soft drink and
            bottling industries since beginning operations on October 1st of,
            1960. <br /> <br /> As one of the longest-standing businesses in
            Nigeria, we have proudly contributed to job creation and partnered
            with many Nigerian suppliers and establishments. <br /> We also
            currently have nine plants across the country.
          </p>
        </div>
        <Link href="/about-us">
        <div className="mb-8 md:mb-0 px-4">
          <button className=" text-white px-10 py-4 font-sofia-pro text-2xl uppercase mt-10 border-2 btn1">
            learn more
          </button>
        </div>
        </Link>
      </div>
      <div className="flex-1 flex flex-col md:flex-row w-full overflow-clip h-full md:h-screen items-end bg-green-800  bg-blend-darken bg-opacity-70 md:bg-transparent ">
        <div className=" hidden md:block w-0 h-1/2 md:w-full"></div>
        
          <div
            
            className={`${
            isHover
              ? "  bg-gradient-to-b from-[#8dc63f] to-[#009444] bg-green-600 w-[400px] h-4/5 md:w-[800px] md:h-3/5 flex justify-center items-center cursor-pointer transition-all duration-500  "
              : " bg-white w-[150px]  h-[400px] md:w-[200px] md:h-3/5 flex justify-center items-center cursor-pointer transition-all duration-500"
          }`}
            style={{
              backgroundImage: `url(${greenStripe})`,
              backgroundSize: "100% 100%",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              transition: "all 0.5s ease-in-out",
            }}
            onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
          >
           {isHover ? (
            <Link to="/game">
            <div className="flex flex-col justify-center items-start gap-12 mb-12 
             "
             >
              <h2 className=" text-white font-bebas-kai text-4xl md:text-5xl whitespace-nowrap">
                You think say <br />
                <span className=" text-6xl md:text-8xl uppercase"> You sabi</span>
              </h2>
              <div className="flex  md:flex-col md:justify-start justify-between items-start gap-4">
                <h4 className="text-white font-bebas-kai text-5xl whitespace-nowrap"> Play A Game </h4>
                <img src={whiteArrow} alt="" className="w-20 h-12" />
              </div>
            </div>
            </Link>
          ) : (
            <div className=" flex justify-center items-center">
              <h2 className="transform -rotate-90 text-[#2A9444] font-bebas-kai text-7xl whitespace-nowrap ">
                {" "}
                Play a game
              </h2>
            </div>
            
          )}
          </div>
          
      </div>
    </div>
    </FadeIn>
  );
};

export default GameSection;
