
import React, { useState } from "react";
import { Navbar } from ".";
import herovideo from "../assets/hero-video.mp4"
import heroimg from "../assets/hero-img.webp"
import herocard from "../assets/hero-card-bg.png"
import redArrow from "../assets/red-right.png"
import whiteArrow from "../assets/white-right.png"
import FadeIn from "./FadeIn";
const Hero = () => {
  const [isToggle, setIsToggle] = useState(false);
  

  return (
    
    <div className="w-full relative min-h-screen"
     >
      <div className="absolute top-0 left-0 w-[100%] md:h-full h-[500px] md:object-fill object-center  z-[-1]">
      
          <video
            className="w-full h-full object-fill object-center "
            autoPlay
            loop
            thumbnail={heroimg}
            poster={heroimg}
            
          >
            <source src={herovideo + "#t=0.1"} 
            type="video/mp4" />
          </video>
       
      </div>

      {/* navbar at the top */}
      <div className="z-10 h-screen md:h-screen flex flex-col justify-between">
    <FadeIn>
        <Navbar />
        </FadeIn>
        <FadeIn>
        <div className="flex  lg:flex-row sm:flex-col justify-between w-full">
          <div className="hidden md:block sm:flex-1"></div>
          {isToggle ? (
            <div
              className="bg-white  w-[600px] h-[470px]  bg-blend-darken bg-cover cursor-pointer transition-all "
              style={{
                backgroundImage: `url(${herocard})`,
              }}
              onClick={() => setIsToggle(!isToggle)}
            >
              <div className="py-10 px-10 flex flex-col md:flex-row md:items-end justify-between gap-12 ">
                <h1 className="text-secondary-green text-[4rem] md:text-7xl lg:text-[5.5rem] uppercase font-bebas-kai font-medium tracking-tighter leading-[60px]">
                  crafting beverages & inspiring change{" "}
                </h1>
                <img
                  src={redArrow}
                  alt="red arrow"
                  className="w-20 md:w-32 md:h-20 md:mr-[20px] mt-2 "
                />
              </div>
            </div>
          ) : (
            <div
              className="bg-primary-green flex-1 h-[400px] flex flex-col justify-center md:justify-center md:h-[400px] lg:h-[450px]  bg-blend-screen bg-cover cursor-pointer transition-all "
              onClick={() => setIsToggle(!isToggle)}
            >
              <div className=" p-4 md:py-10 md:px-10">
                <h1 className="text-white text-[4rem] md:text-7xl lg:text-[5.5rem] uppercase font-bebas-kai font-medium tracking-tighter leading-[60px]">
                  Home for <br /> extraordinary <br /> people{" "}
                </h1>
              </div>
              <div className="flex flex-col md:flex-row lg:flex-row px-4 md:px-10 md:justify-between md:items-center mb-8">
                <p className="text-white font-sofia-pro text-xl">
                  with extraordinary determination <br /> to live extraordinary
                  lives.
                </p>
                <img
                  src={whiteArrow}
                  alt="white arrow"
                  className="w-16 md:w-32 md:h-20 md:mr-[120px] mt-2 "
                />
              </div>
            </div>
          )}
        </div>
    </FadeIn>
      </div>
    </div>
  );
};

export default Hero;
