import React, {useRef} from "react";
import FadeIn from "./FadeIn";
const Info = () => {
  const infoRef = useRef(null);
  
  return (
    <FadeIn>
    <div 
   
    className="w-full h-full py-8 flex justify-center items-center lg:h-[85vh] bg-primary-green text-white font-sofia-pro px-4 lg:px-52">
      <p  ref={infoRef} className=" text-[25px] md:text-[50px] text-center md:pt-20 md:mb-20">
        Carrying some of the biggest brands in the world such as Pepsi and
        manufacturing its brands, Seven-up Bottling Company has elevated the
        beverage industry in Nigeria with a focus on
        <span className="text-[#FB562A]"> refreshing </span>
        the
        <span className="text-[#FB562A]"> well-being </span>
        and
        <span className="text-[#FB562A]"> dreams </span>
        of Nigerians.
      </p>
    </div>
    </FadeIn>
  );
};

export default Info;
