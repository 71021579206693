import React, {useState, useRef} from "react";
import cfo from "../assets/CFO.webp";
import chro from "../assets/CHRO.webp";
import hd from "../assets/HD.webp";
import hm from "../assets/HM.webp";
import cto from "../assets/cto.webp";
import cio from "../assets/cio.webp";
import cmo from "../assets/cmo.png";
import md from "../assets/md.png";
import cso from "../assets/cso.png";
import clo from "../assets/clo.png";
import gmm from "../assets/gmm.png";
import gmb from "../assets/gmb.png";
import greenStripe from "../assets/green-stripe.png";
import { RiArrowRightDoubleLine, RiArrowLeftDoubleLine } from "react-icons/ri";
import FadeIn from "./FadeIn";
const people = [
  {
    title: "Managing Director",
    name: "Ziad Maalouf",
    color: "#FB562A",
    img: md,
  },
  {
    title: "Chief Financial Officer",
    name: "Muhammad Ahmed",
    color: "#FB562A",
    img: cfo,
  },
  {
    title: "Chief Transformation Officer",
    name: "Sari El Khalil",
    color: "#FB562A",
    img: cto,
  },

  {
    title: "Chief Information Officer",
    name: "Vasco Goncalves",
    color: "#FB562A",
    img: cio,
  },
  {
    title: "Chief Marketing Officer",
    name: "Norden Thurston",
    color: "#FB562A",
    img: cmo,
  },


 
  {
    title: "Chief Supply-Chain Officer",
    name: "Radhe Jha",
    color: "#FB562A",
    img: cso,
  },
 
  {
    title: "Head of Distribution",
    name: "Ryad Hijazi",
    color: "#FB562A",
    img: hd,
  },
  {
    title: "Head of Manufacturing",
    name: "Alok Soni",
    color: "#FB562A",
    img: hm,
  },
  {
    title: "Group Head, Culture and Transformation",
    name: "Olayinka Olufade",
    color: "#FB562A",
    img: chro,
  },
  
  {
    title: "General Manager, Marketing",
    name: "Segun Ogunleye",
    color: "#FB562A",
    img: gmm,
  },
  {
    title: "Head of Legal and Corporate Affairs",
    name: "Nkemdirim Agboti",
    color: "#FB562A",
    img: clo,
  },
  {
    title: "General Manager, Business Optimization Intelligence & Technology",
    name: "Dominion Izedonmi",
    color: "#FB562A",
    img: gmb,
  }
];

const OurPeople = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
   const containerRef = useRef(null);

   const itemWidth = 300; 

const handleScroll = (direction) => {
  const container = containerRef.current;
  if (container) {
    const { scrollLeft, scrollWidth } = container;
    const scrollOffset = direction === "forward" ? itemWidth : -itemWidth;
    const newPosition = Math.max(
      0,
      Math.min(scrollLeft + scrollOffset, scrollWidth - itemWidth)
    );
    container.scrollTo({ left: newPosition, behavior: "smooth" });
  }
};


  return (
    <FadeIn>

    <div
      className="w-full min-h-fit md:min-h-screen bg-green-50 bg-opacity-50 flex flex-col items-center mx-auto px-4 sm:px-6 lg:px-2 relative"
      style={{
        backgroundImage: `url(${greenStripe})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        backgroundBlendMode: "overlay",
      }}
    >
      <h1 className="mt-6 text-5xl md:text-8xl tracking-tighter font-bebas-kai text-[#2A9444]">
        Our People
      </h1>
      <div
        ref={containerRef}
        className="mt-8 w-full md:w-11/12 relative mx-auto overflow-auto no-scrollbar"
      >
        <div
          className="flex transition-transform duration-300 ease-in-out"
          style={{
            transform:
              currentIndex === 0
                ? `translateX(0)`
                : `translateX(-${currentIndex * 100}%)`,
                marginRight: currentIndex === people.length - 1 ? "0" : "1rem", 
          }}
        >
            {[...people].map((person, index) => (
            <div
              key={index}
              className="flex-shrink-0 w-full md:w-[300px] h-[450px] overflow-clip m-4 p-4 bg-opacity-50 rounded-lg shadow-lg"
              style={{
                position: "relative",
                backgroundImage: `url(${person.img})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "bottom right",
                backgroundColor: "#2A9444",
              }}
            >
              <div className=" rounded-lg people-card"></div>
              <h2 className="text-4xl font-bold font-bebas-kai tracking-wide text-[#ffffff]">
                {person.name}
              </h2>
              <h3 className="text-sm font-medium text-[#ffffff]">
                {person.title}
              </h3>
            </div>
          ))}
          {/* Trailing dots */}
        </div>

        {/* navigation buttons */}
      </div>
        <div className="flex justify-center items-center gap-4 mt-4 absolute  bottom-5">
          <button
            className="w-8 h-8 rounded-full flex items-center justify-center bg-[#2A9444] text-white"
            onClick={() => handleScroll("backward")}
          >
            <RiArrowLeftDoubleLine className="text-xl" />
          </button>
              {/* {/*  can i have dots here matching the number cards  */}
              <div className="flex gap-2 items-center">
                <span className="w-2 h-2 rounded-full bg-primary-green bg-opacity-45"></span>
                <span className="w-2 h-2 rounded-full bg-primary-green bg-opacity-45"></span>
                <span className="w-2 h-2 rounded-full bg-primary-green bg-opacity-45"></span>
              </div>
          <button
            className="w-8 h-8 rounded-full flex items-center justify-center bg-[#2A9444] text-white"
            onClick={() => handleScroll("forward")}
          >
            <RiArrowRightDoubleLine className="text-xl" />
          </button>
        </div>
    </div>
    </FadeIn>
  );
};

export default OurPeople;
