import React from "react";
import logo from "../assets/sbclogo.png";
import emoji from "../assets/emoji.png";

import { Link } from "react-router-dom";
const Loose = ({ restart }) => {
  return (
    <div
      className="w-full h-screen flex flex-col items-center "
      style={{
        backgroundImage: "url('/gamebg.png')",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        backgroundBlendMode: "overlay",
      }}
    >
      <div
        className=" absolute top-0 left-0 w-full h-full flex flex-col items-center justify-center z-[-1]"
        style={{
          backgroundImage: "url('/gamebg.png')",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
        }}
      ></div>

      <div className="flex gap-4 items-center justify-between w-full mt-4 px-4 z-10 ">
        <Link to="/">
        <img
              src={logo}
              alt="logo"
              width={100}
              height={100}
              className="w-14 md:w-20 "
            />
        </Link>
        <div className=""></div>
        <div className=""></div>
      </div>
      <div className="md:-mt-24 flex flex-col justify-center items-center gap-2 mt-16">
        <div className="flex justify-center items-center w-max">
          <img src={emoji} alt="emoji" className="w-[300px]" />
        </div>

        <div className="flex flex-col justify-center items-center -mt-8">
          <h1 className=" time-txt2 text-secondary-green font-bebas-kai uppercase">
            {" "}
            Better Luck
          </h1>
          <p className="text-xl md:-m-4 mt-6  font-sofia-pro font-light uppercase text-primary-green">
            {" "}
            Next time{" "}
          </p>
          <p className="text-3xl mt-4 md:mt-8 font-sofia-pro uppercase text-primary-orange">
            {" "}
            try again!{" "}
          </p>
        </div>
        {/* a restart button */}
        <div className="flex justify-center items-center mt-2">
          <button
            className="px-12 py-2 text-primary-orange font-sofia-pro text-2xl uppercase mt-8 border-2 border-primary-orange hover:text-white hover:border-none hover:bg-primary-green transition-all duration-500"
            onClick={restart}
          >
            Restart
          </button>
        </div>
      </div>
    </div>
  );
};

export default Loose;
