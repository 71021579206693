
import React, { useState, useEffect } from "react";
import { Footer, Navbar } from "../../components/index";
import logo from "../../assets/sbclogo.png";

import { Link } from "react-router-dom";
import pepsiImg from "../../assets/pepsi-img.png";
import groupPepsi from "../../assets/group-pepsi.png";
import pepsiVid from "../../assets/Pepsi-vid.mp4";
import Loading from "../../components/Loading";
const Pepsi = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000); 

    return () => clearTimeout(timer); 
  }, []);

  if (loading) {
    return  <Loading />;
  }
  return (
    <div className="relative min-h-screen w-full">
      <div className="relative flex flex-col justify-between pepsi min-h-screen ">
        <Link to="/">
          <div className="fixed top-0 left-0 mt-4 ml-6 z-10">
          <img
              src={logo}
              alt="logo"
              width={100}
              height={100}
              className="w-14 md:w-20 "
            />
          </div>
        </Link>

        <div
          className={`relative z-[1] w-full h-screen bg-black ${
            loading ? "" : "pepsi-image"
          }`}
        >
          {loading ? (
            <div className="absolute top-0 left-0 w-[100%] md:h-full h-[500px] md:object-fill object-center  z-[-1]">
              <video
                className="w-full h-full object-bottom md:object-fill md:object-center  "
                autoPlay
                loop
                muted
              >
                <source src={pepsiVid} type="video/mp4" />
              </video>
            </div>
          ) : (
            <div className="absolute top-0 left-0 w-[100%] md:h-full h-[500px] md:object-fill object-center  z-[-1]">
              <img
                src={pepsiImg}
                alt="pepsi"
                layout="fill"
                className="w-full h-full md:object-cover"
              />
            </div>
          )}

          <Navbar />
        </div>
        <div className="w-full h-[500px] md:h-screen group-pepsi">
          {/* <img src={groupPepsi} alt="pepsi" width={500} height={400} className="object-cover"  /> */}
        </div>
        <div className="w-full md:h-screen pma flex relative items-center justify-center">
          <div className="flex-1 w-full h-full md:flex hidden"></div>
          <div className="flex-1  w-full h-full flex justify-center items-center ">
            <button className="mt-72 md:mt-80  px-8 py-4 font-awake text-3xl pepsi-btn">
              Register Now
            </button>
          </div>
        </div>
        <div className="w-full h-max md:h-screen pfa flex relative items-center justify-center">
          <div className="flex-1 w-full h-full flex justify-center items-center ">
            <button className="mt-[30rem]  px-8 py-4 font-awake text-3xl pepsi-btn">
              Register Now
            </button>
          </div>
          <div className="flex-1 w-full h-full md:flex hidden"></div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Pepsi;
