import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import closeIcon from "../assets/close.png";

const links = [
  {
    id: 1,
    url: "/about-us",
    text: "About Us",
  },
  {
    id: 2,
    url: "/products",
    text: "Products",
  },
  {
    id: 3,
    url: "/media",
    text: "Media",
  },
  {
    id: 4,
    url: "/career",
    text: "Career",
  },
  {
    id: 5,
    url: "/contact-us",
    text: "Contact Us",
  },
];

const Navbar = () => {
  const [show, setShow] = useState(false);

  const toggleMenu = () => {
    setShow(!show);
  };

  return (
    <nav className="flex justify-between items-center px-4 lg:px-32 h-[100px] z-auto">
      <div className=""></div>

      <div className="hidden sm:hidden lg:flex gap-10">
        {links.map((link) => {
          const { id, url, text } = link;
          return (
            <NavLink
              key={id}
              to={url}
              className={({ isActive }) =>
                isActive
                  ? "text-[#FFD600] font-bebas-kai text-4xl"
                  : "text-white font-bebas-kai text-4xl hover:text-[#FFD600]"
              }
            >
              {text}
            </NavLink>
          );
        })}
      </div>

      <div
        className="flex sm:flex lg:hidden cursor-pointer"
        onClick={toggleMenu}
      >
        <div className="">
          <span
            className={`w-6 h-1 md:w-10 md:h-2 bg-white block mb-1 md:mb-2 transition duration-300 ${
              show ? "transform -rotate-45 translate-y-2" : ""
            }`}
          ></span>
          <span
            className={`w-6 h-1 md:w-10 md:h-2 bg-white block mb-1 md:mb-2 transition duration-300 ${
              show ? "hidden" : ""
            }`}
          ></span>
          <span
            className={`w-6 h-1 md:w-10 md:h-2 bg-white block mb-1 transition duration-300 ${
              show ? "transform rotate-45 -translate-y-2" : ""
            }`}
          ></span>
        </div>
      </div>

      {show && (
        <div className="flex flex-col gap-10 absolute top-0 left-0 w-full h-screen bg-primary-green z-[1]">
          <div className="flex justify-end items-center px-4 md:px-32 h-[100px] z-auto">
            <div className="cursor-pointer" onClick={toggleMenu}>
              <img src={closeIcon} alt="close" className="w-10 h-10" />
            </div>
          </div>
          <div className="flex flex-col gap-10 justify-center items-center">
            <NavLink
              to="/"
              className="text-white font-bebas-kai text-4xl hover:text-[#FFD600]"
            >
              Home
            </NavLink>
            {links.map((link) => {
              const { id, url, text } = link;
              return (
                <NavLink
                  key={id}
                  to={url}
                  className={({ isActive }) =>
                    isActive
                      ? "text-[#FFD600] font-bebas-kai text-4xl"
                      : "text-white font-bebas-kai text-4xl hover:text-[#FFD600]"
                  }
                >
                  {text}
                </NavLink>
              );
            })}
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
